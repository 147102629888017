import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Dashboard.vue'
import Mixing from '../views/Mixing.vue'
import Mupload from '../views/mixupload.vue'
import Merpload from '../views/merpload.vue'
import Calc from '../views/calc.vue'
import Login from '../views/login.vue'
import Errpage from '../views/errpage.vue'
import Relese from '../views/relese.vue'
import Rdetail from '../views/rdetail.vue'
import Impupload from '../views/impps/impupload.vue'
import Imprelesedoc from '../views/impps/relesedoc.vue'
import Impuploaddoc from '../views/impps/uploaddoc.vue'
import Impcalc from '../views/impps/impcalc.vue'
import Imperr from '../views/impps/imperr.vue'
import Impajust from '../views/impps/impajust.vue'
import Impajust2 from '../views/impps/impajust2.vue'
import Imprelese from '../views/impps/imprelese.vue'
import Performance from '../views/Performance.vue'
import Relesedata from '../views/relesedata.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/mixing',
    name: 'mixing',
    component: Mixing
  },
  {
    path: '/mupload',
    name: 'mupload',
    component: Mupload
  },
  {
     path: '/merpload',
    name: 'merpload',
    component: Merpload
  },
 {
     path: '/calc',
    name: 'calc',
    component: Calc
 },
 {
     path: '/errpage',
    name: 'errpage',
    component: Errpage
  },
 {
     path: '/relese',
    name: 'relese',
    component: Relese
  },
 {
     path: '/rdetail',
    name: 'rdetail',
    component: Rdetail
  },
  {
     path: '/impupload',
    name: 'impupload',
    component: Impupload
  },
   {
     path: '/imprelesedoc',
    name: 'imprelesedoc',
    component: Imprelesedoc
  },
   {
     path: '/impuploaddoc',
    name: 'impuploaddoc',
    component: Impuploaddoc
  },
   {
     path: '/impcalc',
    name: 'impcalc',
    component: Impcalc
  },
   {
     path: '/imperr',
    name: 'imperr',
    component: Imperr
  },
    {
     path: '/impajust',
    name: 'impajust',
    component: Impajust
  },
     {
     path: '/impajust2',
    name: 'impajust2',
    component: Impajust2
  },
      {
     path: '/imprelese',
    name: 'imprelese',
    component: Imprelese
  },
       {
     path: '/performance',
    name: 'performance',
    component: Performance
  },
        {
     path: '/relesedata',
    name: 'relesedata',
    component: Relesedata
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
