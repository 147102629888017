   <template>
    <Header></Header>
    <div class="container">
        <div class="row">
            <div class="col-5 text-center">
                <div class="card bg-dark bg-opacity-25" style="width: 1300px; height: 220px;"><br>
                    <h5 class="fw-bold">RELEASED DOCUMENT RECORD</h5>
                    <hr>
                    <div class="row">
                        <div class="col-2">
                            <div class="text-center">
                                <img src="../assets/user.jpg" alt="Avatar" class="avatar">
                            </div>
                        </div>
                        <div class="col-3 text-start">
                            <br><br>
                            <h5>Dara Super Admin</h5>
                                    <h5>120801003</h5>
                        </div>
                        <div class="col-6 text-start"><br>
                            <div class="row">
                                <div class="col">
                                    <a></a>
                                    <h6>From</h6>
                                <VueDatePicker v-model="date1" style="width: 200px;"></VueDatePicker>
                                </div>
                                <div class="col">
                                     <h6>To</h6>
                                <VueDatePicker v-model="date2" style="width: 200px;"></VueDatePicker>
                                </div>
                            </div>
                            
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="text-danger">
       <div class="card text-center bg-warning bg-opacity-25" style="width: 1300px; height: 500px;">
        <br><br>
            <h5>RELEASED DOCUMENT RECORD</h5>
            <br>
            <div class="container">
               <table class="table table-hover">
                    <thead class="bg-warning text-white">
                        <tr>
                        <th scope="col">No</th>
                        <th scope="col">Release Date</th>
                        <th scope="col">Issuer</th>
                        <th scope="col">Category</th>
                        </tr>
                    </thead>
                    <tbody class="bg-light text-dark">
                        <tr>
                        <th scope="row">1</th>
                        <td>03/May/2023 </td>
                        <td>Ferdinan</td>
                        <td>Mixing Plan</td>
                        </tr>
                        <tr>
                        <th scope="row">2</th>
                        <td>04/May/2023</td>
                        <td>Joe</td>
                        <td>Man Power Plan</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>05/May/2023</td>
                            <td>Ferdinan</td>
                            <td>Mixing Plan</td>
                            </tr>
                    </tbody>
                </table>
            </div>
       </div>
        <br>
        <div class="col-12 text-end">
            <a href="/home" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a>
            <a style="text white"> .</a>
            <a href="/home" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
        </div>
    </div>
</template>

<script>
import Header from '../components/header.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {

    name: 'performance',
    components: {
        Header,
        VueDatePicker
    },
    data() {
        return {
            date1: null,
            date2: null,
        };
    }
}
</script>
<style>
.avatar {
    vertical-align: middle;
    width: 120px;
    border-radius: 50%;

}

.btn-circle.btn-xl {
    width: 110px;
    height: 110px;
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.33;
    border-radius: 50px;
}</style>
