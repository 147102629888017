<template>

  <router-view />
</template>
<script>

export default {
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  },
  methods: {
    setTime() {
      setInterval(() => {
        const date = new Date();
        this.hours = date.getHours();
        this.minutes = this.addLeadingZero(date.getMinutes());
        this.seconds = this.addLeadingZero(date.getSeconds());
      });
    },
    addLeadingZero(number) {
      return ("0" + number).slice(-2);
    },
  },

  mounted() {
    this.setTime();
  },

}
</script>

<style>
body {
  background: url("./assets/wave.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: transparent;
}
.button {
  background-color: #c0d3f2;
  /* Green */
  border: none;
  color: rgb(75, 75, 75);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
  font-size: 16px;
}
.button2 {
  background-color: #dee3f9;
  /* Green */
  border: none;
  color: rgb(75, 75, 75);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}
.button3 {
  background-color: #fce9ad;
  /* Green */
  border: none;
  color: rgb(75, 75, 75);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}
.button4 {
  background-color: #fcd554;
  /* Green */
  border: none;
  color: rgb(75, 75, 75);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}
.notif {
  background-color: #ffffff;
  /* Green */
  border: none;
  color: rgb(75, 75, 75);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}
.clock-inner {
    height: 50%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    line-height: 50px;
    font-family: "VT323";
    color: rgb(3, 3, 3);
    
}
</style>


