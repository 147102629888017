<template>
  <Header></Header>
  <div class="container-fluid">
    <div class="text-center">
      <div class="navbar">
        
      </div>
      <div class="card bg-dark bg-opacity-25 text-center align-middle " style="width: 98vmax ; height: 17vh;">
        <div class="row">
          <div class="col-3 text-center align-items-center">
            <div class="row">
              <div class="col-1 align-middle">
                <br>
                <img src="../assets/user.jpg"  alt="Avatar" class="avatar align-middle">
              </div>
              <div class="col ">
                <br><br>
                <h6>Dara - Super Admin</h6>
                <h6>120801003</h6>

              </div>
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col">
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-2">
                <br>
                 <a href="/relesedata" class="button2 bi bi-file-earmark-text" style="font-size: 2rem;">
                          <h5> Released Document</h5>
                        </a><br>
              </div>
              <div class="col-2">
                <br>
                 <a href="/performance" class="button2 bi bi-speedometer" style="font-size: 2rem;">
                          <h5> Performance Analysis</h5>
                        </a><br>
              </div>
              <div class="col-2">
                <br>
                 <a href="/mixing" class="button2 bi bi-arrow-repeat" style="font-size: 2rem;">
                          <h5> Data update Request</h5>
                        </a><br>
              </div>
              <div class="col-2">
                <br>
                <a  class="notif " style="font-size: 2rem;">
                          <h5> Notification</h5>
                          <h6 class="text-white"> Recent Activities</h6>
                  <h6 class="text-white">10:20 New IMPS has been printed <br>
                  </h6>
                        </a><br>
              </div>
              <div class="col-2">
                <br>
                 <a  class="notif " style="font-size: 2rem;">
                          <h5> Notification</h5>
                          <h6> Recent Activities</h6>
                  <h6>10:20 New IMPS has been printed <br>
                  </h6>
                        </a><br>
              </div>
              <!-- <div class="col-2">
                <div class="card d-grid gap-2" style="width: 15vmax ; height: 10vh;">
                  <h6> Notification</h6></div>
              </div>
              <div class="col-2">
                <div class="card d-grid gap-2" style="width: 15vmax ; height: 10vh;">
                  <h6> Recent Activities</h6>
                  <h6>10:20 New IMPS has been printed <br>
                  10:30 New IMPS has been printed</h6>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="text-center">
          <hr>
          <div class="card">
              <h5>Recent Activities</h5>
              <hr>
              <h6>10:20 New IMPS has been printed <br>
                  10:30 New IMPS has been printed</h6>
            </div>
        </div> -->
       
        <!-- <div class="text-center">
          <a href="" class="button3" style="width: 250px;">Input/Update <br> Man Power Data</a>
        </div><br>
        <div class="text-center">
            <a href="" class="button3" style="width: 250px;">Input/Update <br> Product Data</a>
          </div><br>
          <div class="text-center">
            <a href="" class="button3" style="width: 250px;">Logout</a>
          </div>
   -->
      </div>

    </div>
    <div class="row">
      <div class="col-3">
      </div>
      <div class="col-12">
         <!-- <div class="mx-auto p-2">
            <div class="row">
              <div class="col">
                 <a href="/relesedata" class="button2 bi bi-file-earmark-text" style="font-size: 2rem;">
                          <h5> Released Document</h5>
                        </a><br>
              </div>
              <div class="col">
                 <a href="/performance" class="button2 bi bi-speedometer" style="font-size: 2rem;">
                          <h5> Performance Analysis</h5>
                        </a><br>
              </div>
              <div class="col">
                 <a href="/mixing" class="button2 bi bi-arrow-repeat" style="font-size: 2rem;">
                          <h5> Data update Request</h5>
                        </a><br>
              </div>
            </div>
         </div> -->
          <hr>
          <div class="row">
            <div class="col-6 text-center">
              <p class="align-middle"><br><br><br><br><br><br><br><br>
                <img src="../assets/ipi2.png" alt="Logo" height="75" class="align-text-top">
                <h1 class="fw-bold text-center">I P S</h1>
                <h4 class="fw-semibold text-center">Intelligent Planning System</h4>
              </p>
            </div>
            <div class="col-1">
               <div class="vr" style="height: 580px;"></div>
            </div>
            <div class="col-4">
              <div class="d-grid gap-1">
                    <a href="/mixing" class="button bi bi-boxes" style="font-size: 2rem;">
                      <h3 class="fw-bold">I M P S</h3>
                      <!-- <h6> Intelligent Mixing Planning System</h6> -->
                    </a><br>
                    <a href="/impupload" class="button bi bi-people" style="font-size: 2rem;">
                       <h3 class="fw-bold">I M P P S</h3>
                      <!-- <h5> Intelligent Man Power Planning System</h5> -->
                    </a><br>
                    <button class="button bi bi-view-list" style="font-size: 2rem;">
                      <h3 class="fw-bold"> I-Arranger</h3>
                    </button><br>
                    <button class="button bi bi-speedometer2" style="font-size: 2rem;">
                      <h3 class="fw-bold"> I-Dashboard</h3>
                    </button><br>
                  </div>
            </div>
          </div>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import Header from '../components/header.vue'

export default {
  name: 'Dashboard',
  components: {
      Header,
  }
}
</script>
<style>
.avatar {
  vertical-align: auto;
  width: 20rem ;
  border-radius: 50%;
}
</style>

