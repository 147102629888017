<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-9">
                <br>
                <img src="../assets/ipi.png" alt="Logo" height="75" class="align-text-top">
            </div>
            <div class="col-3 align-middle">
                <br>
                <div id="clock">
                    <div class="clock-inner">
                        <div class="hour">{{ hours }}</div>
                        <div class="dots">:</div>
                        <div class="min">{{ minutes }}</div>
                        <div class="dots">:</div>
                        <div class="secs">{{ seconds }}</div>
                        <div class="mode"></div>
                    </div>
                </div>
                <h5 class="text-start fw-bold fst-italic">Fridey,09 June 2023</h5>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>

export default {
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    },
    methods: {
        setTime() {
            setInterval(() => {
                const date = new Date();
                this.hours = date.getHours();
                this.minutes = this.addLeadingZero(date.getMinutes());
                this.seconds = this.addLeadingZero(date.getSeconds());
            });
        },
        addLeadingZero(number) {
            return ("0" + number).slice(-2);
        },
    },

    mounted() {
        this.setTime();
    },

}
</script>