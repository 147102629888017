<template>
    <Header></Header>
      <div class="container">
                    <h3>I M P S</h3>
                    <h5>Intelligent Mixing Planning System</h5>
                    <hr>
                    <br><br><br>

                    <div class="row">
                        <div class="col-2 text-center">
                            <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 3rem;">
                            <h6 class="text-warning">File Upload</h6>
                            </i>
                            <i class="bi bi-2-circle-fill text-warning" style="font-size: 3rem;">
                             <h6 class="text-warning">Calculation</h6></i>
                            <i class="bi bi-3-circle-fill text-warning" style="font-size: 3rem;">
                             <h6 class="text-warning">Plan adjustment</h6></i>
                            <i class="bi bi-4-circle-fill text-warning" style="font-size: 4rem;">
                             <h6 class="text-warning">Release</h6></i>
                        </div>
                        <div class="col-10">
                            <div class="card bg-light text-white" style="width: 65rem; height: 25rem;">
                                <br>
                                <div class="container">
                                    <h4 class="text-dark">Cost Down Detail</h4><br>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Product<br> Name</th>
                                                <th scope="col">batch <br> before</th>
                                                <th scope="col">batch <br> after</th>
                                                <th scope="col">avoided <br>leftover</th>
                                                <th scope="col">batch <br> Value</th>
                                                <th scope="col">leftover <br> Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">1</th>
                                                <td>ISI TC Tonic Shampoo 3 IN 1</td>
                                                <td>5 batch</td>
                                                <td>4 batch</td>
                                                <td>1</td>
                                                <td>Rp. 1.500.000</td>
                                                <td>-</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">2</th>
                                                <td>ISI GB Eau De Toilette(2003)</td>
                                                    <td>4 batch</td>
                                                    <td>4 batch</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>Rp. 4.000.000</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">3</th>
                                               <td>ISI TC Detangling Cond Shampoo</td>
                                                    <td>3 batch</td>
                                                    <td>2 batch</td>
                                                    <td>1</td>
                                                    <td>Rp. 1.500.000</td>
                                                    <td>Rp. 1.000.000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                        </div>  
                        
                     </div>
                     <div class="row">
                        <div class="col-6"></div>
                         <div class="col-4 text-end">
                                    <div class="p-2 g-col-6" >
                                        <a href="/relese" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                                        <a style="text white"> .</a>
                                        <a href="/home" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                                    </div>
                        </div> 
                     </div>
      </div>

</template>
<script>
import Header from '../components/header.vue'

export default {
    name: 'relese',
    components: {
        Header,
    }
}
</script>