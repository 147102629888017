<template>
    <Header></Header>
    <div class="container">
        <h3>I M P P S</h3>
            <h5>Intelligent Man Power Planning System</h5>
            <hr>
            <br><br>

        <div class="row">
            <div class="col-2 text-center">
                 <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 3rem;">
                        <h6 class="text-warning">File Upload</h6>
                    </i>
                    <i class="bi bi-2-circle-fill text-warning" style="font-size: 3rem;">
                        <h6 class="text-warning">Calculation</h6>
                    </i>
                    <i class="bi bi-3-circle-fill text-warning" style="font-size: 4rem;">
                        <h6 class="text-warning">Plan adjustment</h6>
                    </i>
                    <i class="bi bi-4-circle-fill text-secondary" style="font-size: 3rem;">
                        <h6 class="text-dark">Release</h6>
                    </i>
            </div>
            <div class="col-10">
                <div class="card overflow-x-scroll" style="width: 1000px; height: 550px;">
                    <div class="row">
                        <div class="col-2">
                            <div class="card bg-secondary text-center" style="width: 170px; height: 550px;">
                                <div class="container">
                                    <br>
                                        <button type="button" class="button4 bi bi-people position-relative" style="font-size: 2.5rem; ">
                                           <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                                            !
                                        </span>
                                        </button>
                                        <br><br>
                                    <h5 class="text-white">Lack of MP has been detected</h5>
                                    <br>
                                     <button type="button" class="button4 bi bi-people position-relative" style="font-size: 2.5rem; ">
                                               <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                                                !
                                            </span>
                                            </button>
                                    <br><br>
                                        <h5 class="text-white">Over MP has been detected</h5>
                                        <br>
                                </div>
                            </div>
                        </div>
                        <div class="col-10 ">
                            <div class="container">
                                <br>
                               
                                <div class="scrollme">
                                    <table class="table table-bordered overflow-x-scroll ">
                                        <thead class="fw-bold text-center align-middle">
                                            <tr>
                                            <td  rowspan="2">Packing</td>
                                            <td  rowspan="2">Nama</td>
                                            <td colspan="2">MP Tersedia</td>
                                            <td colspan="2">Kebutuhan MP</td>
                                            <td colspan="2">Selisih</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Primer</th>
                                                <th scope="col">Secunder</th>
                                                <th scope="col">Primer</th>
                                                <th scope="col">Secunder</th>
                                                <th scope="col">Primer</th>
                                                <th scope="col">Secunder</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td  rowspan="6">Packing 1</td>
                                           
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                                <hr>
                                <br>
                                 <h6> PACKING 1 (SHIFT 1)</h6> 
                                <div class="scrollme">
                                    <div class="row">
                                    <div class="col-5">
                                        <br>
                                        <div class="container">
                                            <table class="table table-bordered overflow-x-scroll">
                                            <thead>
                                                <tr>
                                               <th scope="col">no</th>
                                                    <th scope="col">Nip</th>
                                                    <th scope="col">Nama</th>
                                                    <th scope="col">Ketua Regu</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">1</th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                </tr>
                                                <tr>
                                                <th scope="row">2</th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <br><br>
                                        <i class="bi bi-arrow-right-square-fill" style="font-size: 3rem;"></i>
                                    </div>
                                    <div class="col-5">
                                        <br>
                                        <div class="container">
                                            <table class="table table-bordered overflow-x-scroll">
                                            <thead>
                                                <tr>
                                                <th scope="col">Area</th>
                                                <th scope="col">Conveyor</th>
                                                <th scope="col">Produksi </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">Packing 2</th>
                                                <td>A100002</td>
                                                <td></td>
                                                </tr>
                                                <tr>
                                                <th scope="row"></th>
                                                <td></td>
                                                <td></td>
                                               
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                </div>

                            <div class="col text-center">
                                    <div class="p-2 g-col-6" ><br><br>
                                    <a href="/mixing" class="button2 bi bi-backspace" style="font-size: 1,5rem;"> Recalculate</a> 
                                    <a style="text white"> .</a>
                                    <a href="/imprelese" class="button2 bi bi-house-up" style="font-size: 1,5rem;"> Finalize</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
                 <div class="row"> 
                          <div class="col-8"></div>
                          <div class="col-4">
                              <div class="p-2 g-col-6" >
                              <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                              <a style="text white"> .</a>
                              <a href="/" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                              </div>
                          </div>
                      </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../../components/header.vue'

export default {

    name: 'errpage',
    components: {
        Header,
    },
}
</script>
<style>
.scrollme {
    overflow-x: auto;
    overflow-y: auto;
}
</style>