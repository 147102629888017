<template>
    <Header></Header>
    <div class="container">
        <h3>I M P P S</h3>
        <h5>Intelligent Man Power Planning System</h5>
        <hr>
        <br><br><br>

        <div class="row">
            <div class="col-2 text-center">
                <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 3rem;">
                    <h6 class="text-warning">File Upload</h6>
                </i>
                <i class="bi bi-2-circle-fill text-warning" style="font-size: 3rem;">
                    <h6 class="text-warning">Calculation</h6>
                </i>
                <i class="bi bi-3-circle-fill text-warning" style="font-size: 3rem;">
                    <h6 class="text-warning">Plan adjustment</h6>
                </i>
                <i class="bi bi-4-circle-fill text-warning" style="font-size: 4rem;">
                    <h6 class="text-warning">Release</h6>
                </i>
            </div>
            <div class="col-10">
                <div class="card bg-secondary text-white" style="width: 55rem; height: 10rem;">
                    <div class="row">
                        <div class="col-3">
                            <div class="text-center align-middle"><br>
                                <img src="../../assets/selamet.png" width="90" alt="">
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="text-start">
                                <br>
                                <h6>You have avoided 120 minutes of
                                   overwork and 7 person of mis-place MP</h6>
                                
                                <a href="" class="button2 ">Detail</a>
                            </div>
                        </div>
                        <div class="col-4"><br>
                            <h5>Worth :</h5>
                            <h2 class="fst-italic text-danger">Rp. 3.300.000,-</h2>
                        </div>
                    </div>
                </div>
                <div class="card bg-light" style="width: 55rem; height: 20rem;">
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-6">
                            <br>
                            <div class="form-check " style="font-size: 30px;">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Send notification by email
                                </label>
                            </div>
                            <div class="form-check " style="font-size: 30px;">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Send notification by whatapp
                                </label>
                            </div>
                            <div class="form-check " style="font-size: 30px;">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                    print Document
                                </label>
                                <button type="button" class="btn btn-secondary"
                                    style="--bs-btn-padding-y: .5rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: 1rem;">
                                    Print setup
                                </button>
                            </div>
                            <div class="form-check " style="font-size: 30px;">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Saved file directory
                                </label>
                                <a type="button" class="btn btn-secondary "
                                    style="--bs-btn-padding-y: .5rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: 1rem; ">
                                    Select
                                </a>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="text-center">
                                <br>
                                <a href="" type="button" class="btn btn-warning text-white"
                                    style="font-size: 30px; width: 200px;">Preview Document</a><br><br>
                                <a href="/rdetail" type="button" class="btn btn-secondary text-white"
                                    style="font-size: 30px; width: 200px;">Release Document</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-6"></div>
            <div class="col-4 text-end">
                <div class="p-2 g-col-6">
                    <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a>
                    <a style="text white"> .</a>
                    <a href="/" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../../components/header.vue'

export default {
    name: 'relese',
    components: {
        Header,
    }
}
</script>