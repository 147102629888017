<template>
    <Header></Header>
     <div class="container">
                <h3>I M P S</h3>
                <h5>Intelligent Mixing Planning System</h5>
                <hr>
                <br><br><br>

                <div class="row">
                    <div class="col-2">
                        <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 3rem;">
                        <h6 class="text-warning">File Upload</h6>
                        </i>
                        <i class="bi bi-2-circle-fill text-warning" style="font-size: 4rem;">
                         <h6 class="text-warning">Calculation</h6></i>
                        <i class="bi bi-3-circle-fill text-secondary" style="font-size: 3rem;">
                         <h6 class="text-dark">Plan adjustment</h6></i>
                        <i class="bi bi-4-circle-fill text-secondary" style="font-size: 3rem;">
                         <h6 class="text-dark">Release</h6></i>
                    </div>
                    <div class="col-10">
                        <div class="card" style="width: 53rem; height: 30rem;">
                            <div class="card-body">
                                <h5 class="card-title"></h5>
                                <br>
                                <div class="text-center">
                                    
                                    </div><br><br>
                                    <div class="text-center">
                                        <img src="../assets/search.jpg" width="150" alt="">
                                    </div>
                                    <h5 class="fw-bold text-center">Your data is not supported</h5>
                                    <h6 class="text-center">Please refer your data layout and content to IPS standard</h6>
                                   <div class="text-center">
                                    <a class="button2 bi bi-file-earmark-arrow-up text center fw-semibold" href="/mupload" >Reupload Dokumen</a><br>
                                    <a class="text center" href="" >Click here to see document standards</a>
    
                                    </div>
                               
                                    </div>
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-4 text-end">
                                <div class="p-2 g-col-6" >
                                    <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                                    <a style="text white"> .</a>
                                    <a href="/" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                                </div>
                            </div>
                        </div>
                    </div>  
     </div>
           
            
     
</template>
<script>
import Header from '../components/header.vue'

export default {

    name: 'errpage',
    components: {
        Header,
    },
}
</script>