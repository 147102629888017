<template>
    <Header></Header>
    <div class="container">
        <h3>I M P P S</h3>
        <h5>Intelligent Man Power Planning System</h5>
        <hr>
        <br><br>
        <div class="row">
            <div class="col-2 text-center">
                 <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 4rem;">
                        <h6 class="text-warning">File Upload</h6>
                        </i>
                        <i class="bi bi-2-circle-fill text-secondary" style="font-size: 3rem;">
                         <h6 class="text-dark">Calculation</h6></i>
                        <i class="bi bi-3-circle-fill text-secondary" style="font-size: 3rem;">
                         <h6 class="text-dark">Plan adjustment</h6></i>
                        <i class="bi bi-4-circle-fill text-secondary" style="font-size: 3rem;">
                         <h6 class="text-dark">Release</h6></i>
            </div>
            <div class="col-10 text-center">
                <div class="card text-center bg-white bg-opacity-25" style="width: 1000px; height: 500px;">
                    <br>
                    <h4>RELEASED DOCUMENT RECORD</h4>
                    <hr>
                    <div class="container text-center">
                            <form class="d-flex" role="search">
                                <input class="form-control me-2" style="width: 250px;" type="search" placeholder="Search" aria-label="Search">
                                <button class="btn btn-outline-success" type="submit">Search</button>
                            </form>
                                <br>
                            <table class="table bg-white table-hover">
                                <thead class="bg-secondary text-white">
                                    <tr>
                                    <th scope="col">No</th>
                                    <th scope="col">Release Date</th>
                                    <th scope="col">Issuer</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>03/May/2023</td>
                                    <td>Ferdinan</td>
                                    <td>Mixing Plan</td>
                                    <td><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-end fw-bold">
                                <a href="/impajust" class="button3 align-bottom fw-bold">Proceed</a>
                            </div>
                    </div>
                </div>
                <br>
                <div class="row">
                        <div class="col-6"></div>
                         <div class="col-4 ">
                                    <div class="text-end" >
                                        <a href="/mixing" class="button3 bi bi-backspace fw-bold" style="font-size: 1,5rem;"> Back</a> 
                                        <a style="text white"> .</a>
                                        <a href="/" class="button3 bi bi-house-up fw-bold" style="font-size: 1,5rem;"> Home</a>
                                    </div>
                                </div> 
                     </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../../components/header.vue'

export default {

    name: 'impuploadopt',
    components: {
        Header,
    },
}
</script>