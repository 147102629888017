<template>
    <Header></Header>
    <div class="container">
        <div class="row">
            <div class="col-5 text-center">
                  <div class="card bg-dark bg-opacity-25" style="width: 450px; height: 300px;"><br>
                    <h6 class="fw-bold">Performance Report</h6>
                    <hr>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-4">
                            <div class="text-center">
                                <img src="../assets/user.jpg"  alt="Avatar" class="avatar">
                                <br>
                                <br>
                                <h6>Dara <br> Super Admin</h6>
                                <h6>120801003</h6>
                           
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-6 text-start" ><br>
                                <h6>From</h6>
                                <VueDatePicker v-model="date1" style="width: 200px;" ></VueDatePicker><br>
                                <h6>To</h6>
                                <VueDatePicker v-model="date2"  style="width: 200px;"></VueDatePicker>
                        </div>
                    </div>
                    </div>
            </div>
           
            <div class="col-7 text-center">
                <div class="row text-center">
                    <div class="col-3 text-center">
                        <div class="card text-center bg-warning bg-opacity-25" style="width: 240px; height: 300px;" >
                        <br>
                        <h6 class="fw-bold">Prevented <br>
                        Leftover Mixing</h6>
                        <hr>
                        <div class="text-center">
                            <button type="button" class="btn btn-success fw-bold btn-circle btn-xl text-center text-warning"> <h5>1.249</h5> <h6>kg</h6> </button>
                        </div>
                        <hr>
                        <h6 class="fst-italic">Worth :</h6>
                        <h5 class="text-danger fw-bold">Rp. 85.000.000</h5>
                        </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-3 text-center">
                            <div class="card text-center bg-warning bg-opacity-25" style="width: 240px; height: 300px;" >
                            <br>
                            <h6 class="fw-bold">Prevented <br>
                            Over planning</h6>
                            <hr>
                            <div class="text-center">
                                <button type="button" class="btn btn-info fw-bold btn-circle btn-xl text-center text-warning"> <h5>54</h5><h6>x</h6> </button>
                            </div>
                            <hr>
                            <h6 class="fst-italic">Worth :</h6>
                            <h5 class="text-danger fw-bold">Rp. 50.000.000</h5>
                            </div>
                        </div>
                        <div class="col-1"></div>
                    <div class="col-3 text-center">
                            <div class="card text-center bg-warning bg-opacity-25" style="width: 240px; height: 300px;" >
                            <br>
                            <h6 class="fw-bold">Prevented <br>
                            Loss Time</h6>
                            <hr>
                            <div class="text-center">
                                <button type="button" class="btn btn-secondary fw-bold btn-circle btn-xl text-center text-warning"> <h5>12.000</h5> <h6>jam</h6>  </button>
                            </div>
                            <hr>
                            <h6 class="fst-italic">Worth :</h6>
                            <h5 class="text-danger fw-bold"> Rp. 1.005.000.000</h5>
                            </div>
                        </div>
    
                </div>

            </div>
        </div>
        <hr class="text-danger">
        <div id="carouselExampleDark" class="carousel carousel-dark slide">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
            <div class="text-center">
                 <div class="card" style="width: 1300px; height: 400px;">
               <div class="row">
                <div class="col-5 text-center">
                    <br><br>
                    <h5>1-1 Mixing Leftover Report</h5><br>
                    <img class="text-center" src="../assets/tb1.png"  style="width: 600px;" alt="">
                </div>
                <div class="col-1"></div>
                <div class="col-5 text-center">
                        <br><br>
                        <h5>1-2 Mixing Over-Batch Report</h5><br>
                        <img src="../assets/tb1.png" class="text-start"  style="width: 600px;" alt="">
                    </div>
                    <div class="col-1"></div>
               </div>
            </div>
        </div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <div class="text-center">
                     <div class="card" style="width: 1300px; height: 400px;">
                   <div class="row">
                    
                  <div class="col-8 text-center">
                        <br>
                        <h5>2-1 Idle Time Due to Manpower</h5><br>
                        <img class="text-center" src="../assets/tb2.png"  style="width: 700px;" alt="">
                        
                    </div>
                    <div class="col-4 text-center">
                            <br>
                            <h5>2-2 Overplanning Count</h5><br>
                            <img class="text-center" src="../assets/tb3.png"  style="width: 400px;" alt="">
                        </div>
                   </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
           <div class="text-center">
                         <div class="card" style="width: 1300px; height: 400px;">
                       <div class="row">
                    
                      <div class="col-7 text-center">
                            <br>
                            <h5>2-1 Idle Time Due to Manpower</h5><br>
                            <img class="text-center" src="../assets/tb4.png"  style="width: 700px;" alt="">
                        
                        </div>
                        <div class="col-5 text-center">
                                <br>
                                <h5>2-2 Overplanning Count</h5><br>
                                <img class="text-center" src="../assets/tb5.png"  style="width: 450px;" alt="">
                            </div>
                       </div>
                    </div>
                </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <br>
      <div class="col-12 text-end">
        <a href="/home" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                                            <a style="text white"> .</a>
          <a href="/home" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
      </div>  
    </div>

</template>

<script>
import Header from '../components/header.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {

    name: 'performance',
    components: {
        Header,
        VueDatePicker
    },
     data() {
        return {
            date1: null,
            date2: null,
        };
    }
}
</script>
<style>
.avatar {
    vertical-align: middle;
    width: 110px;
    border-radius: 50%;

}
.btn-circle.btn-xl {
  width: 110px;
  height: 110px;
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.33;
  border-radius: 50px;
}
</style>
