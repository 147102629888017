<template>
    <Header></Header>
    <div class="container-fluid">
        <h3>I M P P S</h3>
            <h5>Intelligent Man Power Planning System</h5>
            <hr>
            <br><br>

        <div class="row">
            <div class="col-1 text-center">
                 <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 3rem;">
                        <h6 class="text-warning">File Upload</h6>
                    </i>
                    <i class="bi bi-2-circle-fill text-warning" style="font-size: 3rem;">
                        <h6 class="text-warning">Calculation</h6>
                    </i>
                    <i class="bi bi-3-circle-fill text-warning" style="font-size: 4rem;">
                        <h6 class="text-warning">Plan adjustment</h6>
                    </i>
                    <i class="bi bi-4-circle-fill text-secondary" style="font-size: 3rem;">
                        <h6 class="text-dark">Release</h6>
                    </i>
            </div>
            <div class="col-10">
                <div class="card overflow-x-scroll" style="width: 1630px; height: 450px;">
                    <br>
                    <div class="container-fluid">
                        <div class="scrollme">
                            <table class="table table-bordered overflow-x-scroll ">
                                <thead class="text-center align-middle fw-bold">
                                    <tr>
                                    <td colspan="2">Standard MP</td>
                                    <td rowspan="3">Standar BPM</td>
                                    <td rowspan="3">*AVG BPM</td>
                                    <td colspan="12">Line Leader </td>
                                    <td colspan="8">MP Gap</td>
                                    <td colspan="5">Prod. Forecasting</td>
                                    <td rowspan="3">Line Leader </td>
                                    <td rowspan="3">Judgement</td>
                                    <td rowspan="3">Action</td>
                                    </tr>
                                    <tr>
                                         <td  rowspan="2">Primer</td>
                                         <td  rowspan="2">Secunder</td>
                                        <td rowspan="2">Shift 1</td>
                                        <td  rowspan="2">Pr</td>
                                        <td  rowspan="2">Sc</td>
                                        <td rowspan="2">Shift 2</td>
                                            <td  rowspan="2">Pr</td>
                                            <td  rowspan="2">Sc</td>
                                       <td rowspan="2">Shift 3</td>
                                            <td  rowspan="2">Pr</td>
                                            <td  rowspan="2">Sc</td>
                                       <td rowspan="2"> Non Shift</td>
                                            <td  rowspan="2">Pr</td>
                                            <td  rowspan="2">Sc</td>
                                        <td colspan="2">Shift-1</td>
                                        <td colspan="2">Shift-2</td>
                                        <td colspan="2">Shift-3</td>
                                        <td colspan="2">non-Shift</td>
                                        <td  rowspan="2">LT (hour)</td>
                                       <td  rowspan="2">Start</td>
                                       <td  rowspan="2">Break</td>
                                       <td  rowspan="2">Adj</td>
                                       <td  rowspan="2">End</td>
                                        </tr>

                                        <tr>
                                            <th scope="col">Pr</th>
                                            <th scope="col">Sc</th>
                                            <th scope="col">Pr</th>
                                            <th scope="col">Sc</th>
                                            <th scope="col">Pr</th>
                                            <th scope="col">Sc</th>
                                           <th scope="col">Pr</th>
                                            <th scope="col">Sc</th>
                                            </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th>5</th>
                                    <td>7</td>
                                    <td>135</td>
                                    <td>117</td>
                                    <th>Hanny Hana</th>
                                    <td>3</td>
                                    <td>9</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>-2</th>
                                    <td>2</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>03:33</td>
                                    <td>06:40</td>
                                    <td>90</td>
                                    <td></td>
                                    <td>11:43</td>
                                    <td>Hanny Hana</td>
                                    <td>Ok</td>
                                    <td>
                                        <a href="" class="btn btn-warning">Edit</a>
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>3</th>
                                    <td>6</td>
                                    <td>65</td>
                                    <td>69</td>
                                    <th>Hanny Hana</th>
                                    <td>3</td>
                                    <td>9</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th></th>
                                    <td>3</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>04:50</td>
                                    <td>11:43</td>
                                    <td></td>
                                    <td>60</td>
                                    <td>17:34</td>
                                    <td>Hanny Hana</td>
                                    <td>Over</td>
                                    <td>
                                        <a href="" class="btn btn-warning">Edit</a>
                                    </td>
                                    </tr>
                                    
                                    
                                </tbody>
                                </table>

    
                        </div>

                    </div>
                    <div class="col text-center">
                                <div class="p-2 g-col-6" ><br><br>
                                <a href="/mixing" class="button2 bi bi-backspace" style="font-size: 1,5rem;"> Recalculate</a> 
                                <a style="text white"> .</a>
                                <a href="/impajust2" class="button2 bi bi-house-up" style="font-size: 1,5rem;"> Finalize</a>
                                </div>
                            </div>
                </div>
                 <div class="row"> 
                          <div class="col-8"></div>
                          <div class="col-4">
                              <div class="p-2 g-col-6" >
                              <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                              <a style="text white"> .</a>
                              <a href="/" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                              </div>
                          </div>
                      </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../../components/header.vue'

export default {

    name: 'errpage',
    components: {
        Header,
    },
}
</script>
<style>
.scrollme {
    overflow-x: auto;
}
</style>