<template>
    <Header></Header>
    <div class="container">
        <h3>I M P P S</h3>
        <h5>Intelligent Man Power Planning System</h5>
        <hr>
        <br>
        <div class="row">
            <div class="col 12">
                <div class="row">
                    <div class="col-7">
                        <div class="card text-center algin-middle bg-white bg-opacity-25 " style="width: 700px; height: 500px; ">
                                <br>
                                <h4>Active Working Hour Setup</h4>
                                <br>
                                <div class="container" >
                                    <table class="table">
                                        <thead class="bg-secondary text-white">
                                            <tr>
                                            <th scope="col"></th>
                                            <th scope="col">in</th>
                                            <th scope="col">out</th>
                                            <th scope="col">Break 1</th>
                                            <th scope="col">Break 2</th>
                                            <th scope="col">Break 3</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <th scope="row" class="bg-secondary text-white">Shift 1</th>
                                                <td>06:40</td>
                                                <td>15:00</td>
                                                <td>10</td>
                                                <td>30</td>
                                                <td>10</td>
                                            </tr>
                                            <tr>
                                             <th scope="row" class="bg-secondary text-white">Shift 2</th>
                                             <td>14:30</td>
                                                <td>23:00</td>
                                                <td>10</td>
                                                <td>30</td>
                                                <td>10</td>
                                            </tr>
                                            <tr>
                                             <th scope="row" class="bg-secondary text-white">Shift 3</th>
                                             <td>22:30</td>
                                                <td>07:00</td>
                                                <td>10</td>
                                                <td>30</td>
                                                <td>10</td>
                                            </tr>
                                            <tr>
                                                 <th scope="row" class="bg-secondary text-white">Shift 4</th>
                                                 <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                </tr>
                                                <tr>
                                                 <th scope="row" class="bg-secondary text-white"> Non Shift </th>
                                                 <td>08:00</td>
                                                <td>17:00</td>
                                                <td>10</td>
                                                <td>30</td>
                                                <td>10</td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    </div>
                    <div class="col-5 text-center">
                         <div class="card text-center bg-white bg-opacity-25" style="width: 500px; height: 650px;">
                            <br>
                            <h5>
                                Please choose your data type
                            </h5>
                            <br>
                                        <div >
                                             <a href="/merpload" class="button bi bi-1-square" style="font-size: 4rem; width: 400px;">
                                                <h5>Select from ERP
                                                                Document</h5>
                                            </a>
                                        </div>
                                        <br>
                                        <div >
                                            <a href="/imprelesedoc" class="button bi bi-2-square" style="font-size: 4rem; width: 400px;">
                                            <h5>Select from released
                                            Document</h5>
                                            </a>
                                                    </div><br>
                                        <div>
                                                        <a href="/impuploaddoc" class="button bi bi-3-square" style="font-size: 4rem; width: 400px;">
                                                            <h5> Upload different
                                                                file of planning</h5>
                                                        </a>
                                                    </div>
                                    
                                
                        </div>
                    </div>
                </div>
                
                
                <!-- <div class="card w-100 ">
                    <div class="card-header">
                        <div class="card"></div>
                        <h5>
                            Please choose your data type
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="" style="width: 1000px;">
                            <div class="row">
                                <div class="col-8 text-center">
                                    <div class="card" style="width: 600px; height: 500px;">
                                        <h4>Active Working Hour Setup</h4>

                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="vr" style="height: 600px;"></div>
                                </div>
                                <div class="col-3 text-end">
                                    <div class="d-grid gap-2">
                                         <a href="/merpload" class="button bi bi-1-square" style="font-size: 4rem;">
                                            <h5>Select from released
                                                            Document</h5>
                                        </a>
                                    </div>
                                    <br>
                                    <div class="d-grid gap-2">
                                                    <a href="/merpload" class="button bi bi-1-square" style="font-size: 4rem;">
                                                        <h5>Select from released
                                                            Document</h5>
                                                    </a>
                                                </div><br>
                                    <div class="d-grid gap-2">
                                                    <a href="/mupload" class="button bi bi-3-square" style="font-size: 4rem;">
                                                        <h5> Upload different
                                                            file of planning</h5>
                                                    </a>
                                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div> -->
                <br>
                <div class="row">
                    <div class="col-9"></div>
                    <div class="col-3">
                        <div class="p-2 g-col-6">
                            <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a>
                            <a style="text white"> .</a>
                            <a href="/" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import Header from '../../components/header.vue'

export default {

    name: 'impuploadopt',
    components: {
        Header,
    },
}
</script>