<template>
    <Header></Header>
    <div class="container">
        <h3>I M P P S</h3>
        <h5>Intelligent Man Power Planning System</h5>
        <hr>
        <br><br><br>

        <div class="row">
            <div class="col-2 text-center">
                <i class="bi bi-1-circle-fill text-warning align-middle" style="font-size: 4rem;">
                    <h6 class="text-warning">File Upload</h6>
                </i>
                <i class="bi bi-2-circle-fill text-secondary" style="font-size: 3rem;">
                    <h6 class="text-dark">Calculation</h6>
                </i>
                <i class="bi bi-3-circle-fill text-secondary" style="font-size: 3rem;">
                    <h6 class="text-dark">Plan adjustment</h6>
                </i>
                <i class="bi bi-4-circle-fill text-secondary" style="font-size: 3rem;">
                    <h6 class="text-dark">Release</h6>
                </i>
            </div>
            <div class="col-10 text-center">
                <div class="card" style="width: 53rem; height: 35rem;">
                    <div class="card-body">
                        <h5 class="card-title">Import Data From File</h5>
                        <form>
                            <fieldset class="upload_dropZone text-center mb-3 p-4">
                                <br><br>
                                <legend class="visually-hidden">File uploader</legend>
                                <i class="bi bi-file-earmark-arrow-up" style="font-size: 4rem;"></i>
                                <p class="small my-2">Drag &amp; Drop File<br><i>or</i></p>
                                <input id="upload_image_background" data-post-name="image_background"
                                    data-post-url="https://someplace.com/image/uploads/backgrounds/"
                                    class="position-absolute invisible" type="file" multiple
                                    accept="image/jpeg, image/png, image/svg+xml" />
                                <a href="" class="button3" for="upload_image_background">Choose file</a>
                            </fieldset>
                        </form>
                        <div class="p-2 g-col-6">
                            <a href="/impcalc" class="button3 bi bi-cloud-arrow-up" style="font-size: 1,5rem;"> Upload</a>
                            <a style="text white"> .</a>
                            <a href="/" class="button3 bi bi-x-square" style="font-size: 1,5rem;"> Cancel</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-4">
                        <div class="p-2 g-col-6">
                            <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a>
                            <a style="text white"> .</a>
                            <a href="/" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="spinner-border text-warning " style="width: 8rem; height: 8rem;" role="status">
            <span class="visually-hidden fw-bold">Loading...</span> -->
        <!-- </div> -->

    </div>
</template>
<script>
import Header from '../../components/header.vue'

export default {
    name: 'mixuploud',
    components: {
        Header,
    }
}
console.clear();
('use strict');


// Drag and drop - single or multiple image files
// https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
// https://codepen.io/joezimjs/pen/yPWQbd?editors=1000
(function () {

    'use strict';

    // Four objects of interest: drop zones, input elements, gallery elements, and the files.
    // dataRefs = {files: [image files], input: element ref, gallery: element ref}

    const preventDefaults = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    const highlight = event =>
        event.target.classList.add('highlight');

    const unhighlight = event =>
        event.target.classList.remove('highlight');

    const getInputAndGalleryRefs = element => {
        const zone = element.closest('.upload_dropZone') || false;
        const gallery = zone.querySelector('.upload_gallery') || false;
        const input = zone.querySelector('input[type="file"]') || false;
        return { input: input, gallery: gallery };
    }

    const handleDrop = event => {
        const dataRefs = getInputAndGalleryRefs(event.target);
        dataRefs.files = event.dataTransfer.files;
        handleFiles(dataRefs);
    }


    const eventHandlers = zone => {

        const dataRefs = getInputAndGalleryRefs(zone);
        if (!dataRefs.input) return;

        // Prevent default drag behaviors
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(event => {
            zone.addEventListener(event, preventDefaults, false);
            document.body.addEventListener(event, preventDefaults, false);
        });

        // Highlighting drop area when item is dragged over it
        ;['dragenter', 'dragover'].forEach(event => {
            zone.addEventListener(event, highlight, false);
        });
        ;['dragleave', 'drop'].forEach(event => {
            zone.addEventListener(event, unhighlight, false);
        });

        // Handle dropped files
        zone.addEventListener('drop', handleDrop, false);

        // Handle browse selected files
        dataRefs.input.addEventListener('change', event => {
            dataRefs.files = event.target.files;
            handleFiles(dataRefs);
        }, false);

    }


    // Initialise ALL dropzones
    const dropZones = document.querySelectorAll('.upload_dropZone');
    for (const zone of dropZones) {
        eventHandlers(zone);
    }


    // No 'image/gif' or PDF or webp allowed here, but it's up to your use case.
    // Double checks the input "accept" attribute
    const isImageFile = file =>
        ['image/jpeg', 'image/png', 'image/svg+xml'].includes(file.type);


    function previewFiles(dataRefs) {
        if (!dataRefs.gallery) return;
        for (const file of dataRefs.files) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let img = document.createElement('img');
                img.className = 'upload_img mt-2';
                img.setAttribute('alt', file.name);
                img.src = reader.result;
                dataRefs.gallery.appendChild(img);
            }
        }
    }

    // Based on: https://flaviocopes.com/how-to-upload-files-fetch/
    const imageUpload = dataRefs => {

        // Multiple source routes, so double check validity
        if (!dataRefs.files || !dataRefs.input) return;

        const url = dataRefs.input.getAttribute('data-post-url');
        if (!url) return;

        const name = dataRefs.input.getAttribute('data-post-name');
        if (!name) return;

        const formData = new FormData();
        formData.append(name, dataRefs.files);

        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('posted: ', data);
                if (data.success === true) {
                    previewFiles(dataRefs);
                } else {
                    console.log('URL: ', url, '  name: ', name)
                }
            })
            .catch(error => {
                console.error('errored: ', error);
            });
    }


    // Handle both selected and dropped files
    const handleFiles = dataRefs => {

        let files = [...dataRefs.files];

        // Remove unaccepted file types
        files = files.filter(item => {
            if (!isImageFile(item)) {
                console.log('Not an image, ', item.type);
            }
            return isImageFile(item) ? item : null;
        });

        if (!files.length) return;
        dataRefs.files = files;

        previewFiles(dataRefs);
        imageUpload(dataRefs);
    }

})();
</script>
<style>
:root {
    --colorPrimaryNormal: #cebd74;
    --colorPrimaryDark: #00979f;
    --colorPrimaryGlare: #00cdd7;
    --colorPrimaryHalf: #80d9dd;
    --colorPrimaryQuarter: #bfecee;
    --colorPrimaryEighth: #dff5f7;
    --colorPrimaryPale: #f3f5f7;
    --colorPrimarySeparator: #f3f5f7;
    --colorPrimaryOutline: #dff5f7;
    --colorButtonNormal: #00b3bb;
    --colorButtonHover: #00cdd7;
    --colorLinkNormal: #00979f;
    --colorLinkHover: #00cdd7;
}




.upload_dropZone {
    color: #0f3c4b;
    background-color: var(--colorPrimaryPale, #c8dadf);
    outline: 2px dashed var(--colorPrimaryHalf, #c1ddef);
    outline-offset: -12px;
    width: 800px;
    height: 400px;
    transition:
        outline-offset 0.2s ease-out,
        outline-color 0.3s ease-in-out,
        background-color 0.2s ease-out;

}

.upload_dropZone.highlight {
    outline-offset: -4px;
    outline-color: var(--colorPrimaryNormal, #0576bd);
    background-color: var(--colorPrimaryEighth, #c8dadf);
}

.upload_svg {
    fill: var(--colorPrimaryNormal, #0576bd);
}

.btn-upload {
    color: #bdac77;
    background-color: var(--colorPrimaryNormal);
}

.btn-upload:hover,
.btn-upload:focus {
    color: #a19161;
    background-color: var(--colorPrimaryGlare);
}

.upload_img {
    width: calc(33.333% - (2rem / 3));
    object-fit: contain;
}</style>