<template>
    <Header></Header>
    <div class="container">
        <h3>I M P S</h3>
        <h5>Intelligent Mixing Planning System</h5>
        <hr>
        <br><br><br>
        <div class="row">

            <div class="col 12">
                <div class="card w-100 bg-white bg-opacity-25 ">
                    <div class="card-header">
                        <h5>
                            Please choose your data type
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="mx-auto p-2" style="width: 1000px;">
                            <div class="row">
                                    <table>
                                        <td></td>
                                        <td>
                                            <br><br>
                                            <div class="d-grid gap-2">
                                                <a href="/merpload" class="button bi bi-1-square" style="font-size: 4rem;">
                                                    <h5> Direct import from
                                                        existing ERP</h5>
                                                </a>
                                            </div>
                                            <br><br>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <div class="vr" style="height: 200px;"></div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <br><br>
                                            <div class="d-grid gap-2">
                                                <a href="/mupload" class="button bi bi-2-square" style="font-size: 4rem;">
                                                    <h5> Upload different
                                                        file of planning</h5>
                                                </a>
                                            </div>
                                            <br><br>
                                        </td>
                                        <td></td>
                                    </table>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-9"></div>
                    <div class="col-3">
                        <div class="p-2 g-col-6" >
                        <a href="/home" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                        <a style="text white"> .</a>
                        <a href="/home" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    </div>
</template>
<script>
import Header from '../components/header.vue'

export default {

    name: 'errpage',
    components: {
        Header,
    },
}
</script>