<template>
    <div class="container">
        <br><br><br><br><br>
        <div class="card align-middle bg-dark bg-opacity-10" >
            <table class="table">
                <tbody>
                    <tr>
                        <div class="col-4">
                                <td class="align-middle">
                                <p class="text-center">
                                    <a href="">
                                        <img src="../assets/ipi2.png" width="100" >
                                    </a>
                                 <br>
                                    <h1 class="fw-bold text-center">I P S</h1>
                                    <h4 class="fw-semibold text-center">Intelligent Planning System</h4>
                                </p>
                            </td>
                            </div>
                            <div class="col-2">
                        <td>
                                <div class="vr" style="height: 600px;"></div>
                            </td>
                        </div>
                        <div class="col-6">
                        <td class="align-middle">
                                <h3 class="text-center">Login</h3>
                                    <h5 class="text-center">Welcome Back ..</h5>

                                    <br><br>
                           
                                <div>
                                    <div class="mb-3 row">
                                            <label for="inputuser" class="col-sm-3 col-form-label fw-bold fs-5">Username</label>
                                            <div class="col-sm-8">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Username">
                                            </div>
                                        </div>
                                        <div class="mb-3 row">
                                            <label for="inputPassword" class="col-sm-3 col-form-label fw-bold fs-5">Password</label>
                                            <div class="col-sm-8">
                                            <input type="password" class="form-control" id="inputPassword">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="mb-4 text-center">
                                            <a href="/home" class="button3 bi bi-box-arrow-in-right fw-bold" style="color:midnightblue;" > Login</a>.
                                            <a href="/" class="button3 bi bi-x-square fw-bold" style="color:midnightblue;"> Cancel</a>
                                        </div>
                                    </div>
                                </td>
                            </div>
                        </tr>
                        </tbody>
                    </table>
        </div>
    </div>
</template>