<template>
  <!-- <div id="loader" class="center"></div> -->
  <Header></Header>
    <div class="container">
        <h3>I M P S</h3>
        <h5>Intelligent Mixing Planning System</h5>
        <hr>
        <br><br><br>
        <div class="row">
            <div class="col-2 text-center">
                  <i class="bi bi-1-circle-fill text-warning" style="font-size: 3rem;"><h6 class="text-warning">File Upload</h6></i>
                  <i class="bi bi-2-circle-fill text-warning" style="font-size: 3rem;"> <h6 class="text-warning">Calculation</h6></i>
                  <i class="bi bi-3-circle-fill text-warning" style="font-size: 4rem;"><h6 class="text-warning">Plan adjustment</h6></i>
                  <i class="bi bi-4-circle-fill text-secondary" style="font-size: 3rem;"><h6 class="text-dark">Release</h6></i>
            </div>
            <div class="col-10">
              <div class="card bg-white bg-opacity-25" style="width: 65rem; height: 32rem;">
                
                <div class="d-grid gap-2">
                  <div class="card-body">
                    <h4 class="card-title">Daily Mixing Plan</h4><br>
                    <!-- <h6 class="card-subtitle mb-2 text-body-secondary">Daily Mixing Plan</h6> -->
                    <div class="overflow-y-scroll">
                      <table class="table table-striped bg-light ">
                        <thead >
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">code</th>
                            <th scope="col">Name</th>
                            <th scope="col">Total Needs</th>
                            <th scope="col">scheme</th>
                            <th scope="col">over production</th>
                            <th scope="col">machine</th>
                            <th scope="col">Lot</th>
                            <th scope="col">information</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>S67201</td>
                            <td>ISI TC Tonic Shampoo 3 IN 1</td>
                            <td>5000</td>
                            <td>2*2500</td>
                            <td>0</td>
                            <td>SH 1</td>
                            <td></td>
                            <td></td>
                            <td> <a href="" class="btn btn-info" > Edit</a></td>
                            
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>S1000503</td>
                              <td>ISI GB Eau De Toilette(2003)</td>
                              <td>7000</td>
                              <td>(1 * 4000)+(1 * 3000)</td>
                              <td>0</td>
                              <td>MX 4</td>
                              <td></td>
                              <td></td>
                              <td> <a href="" class="btn btn-info" > Edit</a></td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td>S1001501</td>
                              <td>ISI TC Detangling Cond Shampoo</td>
                              <td>4000</td>
                              <td>1*4000</td>
                              <td>0</td>
                              <td>SH 1</td>
                              <td></td>
                              <td></td>
                              <td> <a href="" class="btn btn-info" > Edit</a></td>
                          </tr>
                          
                        </tbody>
                      </table>

                    </div>
                          <br>
                          <div class="row"> 
                        
                        <div class="col text-center">
                            <div class="p-2 g-col-6" ><br><br>
                            <a href="/mixing" class="button2 bi bi-backspace" style="font-size: 1,5rem;"> Recalculate</a> 
                            <a style="text white"> .</a>
                            <a href="/relese" class="button2 bi bi-house-up" style="font-size: 1,5rem;"> Finalize</a>
                            </div>
                        </div>
                    </div>

                  </div>
                </div>
              </div>
                <div class="row"> 
                      <div class="col-8"></div>
                      <div class="col-4">
                          <div class="p-2 g-col-6" >
                          <a href="/mixing" class="button3 bi bi-backspace" style="font-size: 1,5rem;"> Back</a> 
                          <a style="text white"> .</a>
                          <a href="/home" class="button3 bi bi-house-up" style="font-size: 1,5rem;"> Home</a>
                          </div>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../components/header.vue'

export default {
  name: 'merpload',
  components: {
    Header,
  }
}

</script>
